import { node } from 'prop-types';
import React, { useContext, useState } from 'react';
import { LOCALSTORAGE_KEY } from '~/api/auth';

const getUser = () => {
  try {
    const sAuth = localStorage.getItem(LOCALSTORAGE_KEY);
    const auth = JSON.parse(sAuth);

    return auth?.user;
  } catch (e) {
    return null;
  }
};

const UserContext = React.createContext();

export function UserContextProvider({ children }) {
  const user = getUser();
  const [currentUser, setCurrentUser] = useState(user);

  return (
    <UserContext.Provider
      value={{
        user: currentUser,
        setUser: setCurrentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

UserContextProvider.propTypes = {
  children: node.isRequired,
};

export function useUserContext() {
  const { user, setUser } = useContext(UserContext);

  return {
    user,
    setUser,
  };
}

export default UserContext;
