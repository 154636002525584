import React from 'react';
import propTypes, { string } from 'prop-types';
import { NavigationSidebar } from '@bitsoflove/pattyn-360/lib/components';
import { useTranslation } from 'react-i18next';
import LinkComponent from '~/components/Link';
import routes from '~/routes';
import useActiveRoute from '~/hooks/useActiveRoute';

const THIRD_PARTY_MACHINE_LINKS = [];

const DEFAULT_MACHINE_LINKS = [
  {
    ...routes.frequentlyAskedQuestions,
    icon: 'faq',
    label: 'frequently_asked_questions.title',
  },
  {
    ...routes.notifications,
    icon: 'notifications',
    label: 'notifications.title',
  },
  {
    ...routes.parameters,
    icon: 'parameters',
    label: 'parameters.title',
  },
  {
    ...routes.modules,
    icon: 'modules',
    label: 'modules.title',
  },
  {
    ...routes.tutorials,
    icon: 'search',
    label: 'tutorials.title',
  },
  {
    ...routes.sparePartBook,
    icon: 'spare-part-book',
    label: 'spare_part_book.title',
  },
  {
    ...routes.electricalPlan,
    icon: 'electrical-plan',
    label: 'electrical_plan.title',
  },
  {
    ...routes.manualsAndDocuments,
    icon: 'tc-export-file',
    label: 'manuals_and_documents.title',
  },
];

const CPRM_MACHINE_LINKS = [
  {
    ...routes.frequentlyAskedQuestions,
    icon: 'faq',
    label: 'frequently_asked_questions.title',
  },
  {
    ...routes.cprmMessages,
    icon: 'notifications',
    label: 'messages.title',
  },
  {
    ...routes.cprmSettings,
    icon: 'parameters',
    label: 'settings.title',
  },
  {
    ...routes.modules,
    icon: 'modules',
    label: 'modules.title',
  },
  {
    ...routes.tutorials,
    icon: 'search',
    label: 'tutorials.title',
  },
  {
    ...routes.sparePartBook,
    icon: 'spare-part-book',
    label: 'spare_part_book.title',
  },
  {
    ...routes.electricalPlan,
    icon: 'electrical-plan',
    label: 'electrical_plan.title',
  },
  {
    ...routes.manualsAndDocuments,
    icon: 'tc-export-file',
    label: 'manuals_and_documents.title',
  },
];

const MachineMenuItem = ({
  serialNumber,
  machineInstanceId,
  machine,
  isCPRM,
}) => {
  const { name } = machine;
  const { t } = useTranslation('pages');
  const { active, check } = useActiveRoute(routes.machine, {
    machineInstanceId,
  });

  /**
   * Get machine links based on machine type
   * @returns array
   */
  const getMachineLinks = () => {
    const machineTypeSlug = machine.type.slug;

    if (isCPRM) return CPRM_MACHINE_LINKS;

    switch (machineTypeSlug) {
      case 'default':
        return DEFAULT_MACHINE_LINKS;
      case '3rd-party':
        return THIRD_PARTY_MACHINE_LINKS;
      default:
        // eslint-disable-next-line no-console
        console.warn(`Unknown machine type ${machineTypeSlug}'`);
    }
    return [];
  };

  const machineLinks = getMachineLinks();
  const hasMachineLinks =
    Array.isArray(machineLinks) && machineLinks.length > 0;

  return (
    <NavigationSidebar.Item
      LinkComponent={LinkComponent}
      linkProps={{
        ...routes.machine,
        query: { machineInstanceId },
      }}
      icon="machine"
      label={`      ${name} - ${serialNumber}`}
      active={active}
    >
      {hasMachineLinks ? (
        <NavigationSidebar.MachineMenu>
          {machineLinks.map(link => {
            return (
              <NavigationSidebar.Item
                key={link.pathname}
                LinkComponent={LinkComponent}
                linkProps={{
                  ...link,
                  query: { machineInstanceId },
                }}
                icon={link.icon}
                label={`  ${t(link.label)}`}
                active={check(link, { machineInstanceId }, true)}
              />
            );
          })}
        </NavigationSidebar.MachineMenu>
      ) : null}
    </NavigationSidebar.Item>
  );
};

MachineMenuItem.propTypes = {
  serialNumber: propTypes.string.isRequired,
  machineInstanceId: propTypes.string.isRequired,
  machine: propTypes.shape({
    id: string.isRequired,
    name: propTypes.string.isRequired,
    type: propTypes.object.isRequired,
  }).isRequired,
  isCPRM: propTypes.bool.isRequired,
};

export default MachineMenuItem;
