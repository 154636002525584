import { css } from '@emotion/core';
import { global } from '@bitsoflove/pattyn-360/lib/styles';
import fonts from './fonts';
import icons from './icons';

const globalStyles = props => css`
  ${global(props)}
  ${fonts}
  ${icons}

  html, body, #root {
    height: 100%;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  .sticky-container {
    height: 100%;
    overflow-y: auto;
  }

  .h-full {
    height: 100%
  }


  mark.search-highlight {
    background-color: #DEEFF7;
  }
`;

export default globalStyles;
