import { ENVIRONMENT } from '../config';

/**
 * Works like the Laravel env helper.
 *  env() -> returns the environment
 *  env('production') -> returns true if the environment is production
 *  env('production', 'staging') -> returns true if one of the arguments matches the environment
 */
export default function env(...checks) {
  if (checks.length === 0) {
    return ENVIRONMENT;
  }

  return checks.includes(ENVIRONMENT);
}
