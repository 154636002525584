import React from 'react';
import propTypes from 'prop-types';
import { NavigationSidebar } from '@bitsoflove/pattyn-360/lib/components';
import LinkComponent from '~/components/Link';
import routes from '~/routes';
import useActiveRoute from '~/hooks/useActiveRoute';
import LineMenuItem from './LineMenuItem';

const SiteMenuItem = ({ site }) => {
  const { name, id: siteId, lines } = site;
  const { active } = useActiveRoute(routes.site, { siteId });

  return (
    <NavigationSidebar.Item
      icon="site"
      label={`  ${name}`}
      LinkComponent={LinkComponent}
      linkProps={{
        ...routes.site,
        query: { siteId },
      }}
      active={active}
    >
      {lines && lines.map(line => <LineMenuItem key={line.id} line={line} />)}
    </NavigationSidebar.Item>
  );
};

SiteMenuItem.propTypes = {
  site: propTypes.shape({
    name: propTypes.string.isRequired,
    id: propTypes.string.isRequired,
    lines: propTypes.arrayOf(propTypes.object),
  }).isRequired,
};

export default SiteMenuItem;
