import routes from '~/routes';

export const AUTH_ROUTES = {
  authenticated: routes.home.pathname,
  login: routes.login.pathname,
  requestPassword: routes.requestPassword.pathname,
};

const AUTH_CONFIG = {
  routes: AUTH_ROUTES,
};

export default AUTH_CONFIG;
