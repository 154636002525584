import { useRouter } from 'next/router';
import { node } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useStoreon } from 'storeon/react';
import { useAuth } from '~/api/AuthProvider';
import routes, { isClientSpecificRoute } from '~/routes';

const STORAGE_KEY = 'pattyn360_selected_client_id';

const clientsByPath = {};

function rememberClient(clientId) {
  localStorage.setItem(STORAGE_KEY, clientId);
}

const ClientContext = React.createContext();

function findClient(clients, { siteId, lineId, machineInstanceId }) {
  return clients?.find(client => {
    return !!client?.sites?.find(site => {
      return (
        site.id === siteId ||
        !!site.lines?.find(line => {
          return (
            line.id === lineId ||
            !!line.machineInstances?.find(instance => {
              return instance.id === machineInstanceId;
            })
          );
        })
      );
    });
  });
}

function determineCurrentRouteClient({ query }, clients) {
  const client = findClient(clients, query);
  if (client) {
    return client.id;
  }
  return null;
}

export function ClientContextProvider({ children }) {
  const router = useRouter();
  const { pathname, asPath } = router;
  const [currentClientId, setCurrentClientId] = useState(null);
  const { sites, dispatch } = useStoreon('sites');
  const { authenticated } = useAuth();

  useEffect(() => {
    if (authenticated) {
      dispatch('sites/fetch');
    }
  }, [authenticated]);

  useEffect(() => {
    if (isClientSpecificRoute(pathname)) {
      // find out which client this route belongs to
      let pathClient = clientsByPath[asPath];
      if (!pathClient) {
        pathClient = determineCurrentRouteClient(router, sites?.clients || []);
        if (pathClient) {
          clientsByPath[asPath] = pathClient;
        }
      }
      if (pathClient && pathClient !== currentClientId) {
        setCurrentClientId(pathClient);
      }
    } else if (!currentClientId) {
      setCurrentClientId(localStorage.getItem(STORAGE_KEY));
    }
  }, [currentClientId, asPath, sites]);

  useEffect(() => {
    if (sites?.clients?.length > 0) {
      // if currently set client is not found, reset to the first one in the list
      if (!sites.clients.find(({ id }) => id === currentClientId)) {
        setCurrentClientId(sites.clients[0].id);
      }
    }
  }, [sites, currentClientId]);

  return (
    <ClientContext.Provider
      value={{
        currentClientId,
        setCurrentClientId: async clientId => {
          if (isClientSpecificRoute(pathname)) {
            clientsByPath[asPath] = currentClientId;
            await router.push(routes.home);
          }
          setCurrentClientId(clientId);
          rememberClient(clientId);
        },
      }}
    >
      {children}
    </ClientContext.Provider>
  );
}

ClientContextProvider.propTypes = {
  children: node.isRequired,
};

export function useClientContext() {
  const { currentClientId, setCurrentClientId } = useContext(ClientContext);
  const { sites } = useStoreon('sites');
  const { loading, clients } = sites;

  return {
    loading,
    currentClientId,
    currentClient:
      currentClientId && clients?.find(({ id }) => id === currentClientId),
    setCurrentClientId,
    clients: clients || [],
    sites: !currentClientId
      ? []
      : clients?.find(({ id }) => id === currentClientId)?.sites,
  };
}

export default ClientContext;
