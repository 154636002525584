import { InMemoryCache, ApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { LANGUAGE_LOCAL_STORAGE_KEY } from '~/context/LanguageContext';
import { DEFAULT_LANGUAGE } from '~/i18n';
import { authLink, authErrorLink } from './auth';
import httpLink from './httpLink';

export function setClientToken() {
  // does nothing, this is here just to make the AuthProvider compatible with the client used in the CMS project
}

/**
 * Add an accept language header
 */
export const localeLink = setContext(async (_, { headers }) => {
  let locale = window.localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY);

  try {
    locale = JSON.parse(locale);
  } catch {
    locale = DEFAULT_LANGUAGE;
  }

  if (!locale) {
    return null;
  }

  return {
    headers: {
      ...headers,
      'accept-language': locale,
    },
  };
});

export default new ApolloClient({
  link: authLink
    .concat(authErrorLink)
    .concat(localeLink)
    .concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});
