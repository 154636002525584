import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@bitsoflove/pattyn-360/lib/components';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  padding: 5px 10px 5px 10px;

  > div > button {
    border-color: transparent;
    color: ${p => p.theme.colors.white};
  }
`;

function SidebarClientSelect({ clients, currentClient, onChangeClient }) {
  const { t } = useTranslation('containers');

  if (clients.length <= 1) {
    return null;
  }

  return (
    <Wrapper>
      <Dropdown
        options={clients.map(({ id, name }) => ({ value: id, label: name }))}
        placeholder={t('sidebar.select_client.placeholder')}
        searchPlaceholder={t('sidebar.select_client.search_placeholder')}
        value={
          currentClient && {
            value: currentClient.id,
            label: currentClient.name,
          }
        }
        onChange={({ value }) => onChangeClient({ id: value })}
        filterOptions
      />
    </Wrapper>
  );
}
SidebarClientSelect.propTypes = {
  clients: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      name: propTypes.string,
    }),
  ),
  currentClient: propTypes.shape({
    id: propTypes.string,
    name: propTypes.string,
  }),
  onChangeClient: propTypes.func.isRequired,
};
SidebarClientSelect.defaultProps = {
  clients: [],
  currentClient: null,
};

export default SidebarClientSelect;
