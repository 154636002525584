/* eslint react/no-danger:0 */

import React, { useEffect } from 'react';
import Router from 'next/router';
import { GTM_TRACKING_ID } from '../config';

/**
 * Track a pageview through the gtag datalayer
 */
export const pageview = url => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url,
      },
    });
  }
};

/**
 *  Track an event through the gtag datalayer
 */
export const event = ({ eventName, eventProps }) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      eventProps,
    });
  }
};

export const useViewEvent = (...props) => {
  useEffect(() => {
    event(...props);
  }, []);
};

/**
 * Insert this component into the Head tree of pages/_document
 */
export const GTMHeadScript = GTM_TRACKING_ID
  ? () => (
      <>
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_TRACKING_ID}');`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){ dataLayer.push(arguments); }
                gtag('js', new Date());
                gtag('config', '${GTM_TRACKING_ID}');`,
          }}
        />
      </>
    )
  : () => null;

export const GTMBodyScript = GTM_TRACKING_ID
  ? () => (
      <noscript>
        <iframe
          title="gtag"
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_TRACKING_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    )
  : () => null;

export const GTMPageTracker = GTM_TRACKING_ID
  ? ({ children }) => {
      useEffect(() => {
        const listener = url => pageview(url);
        Router.events.on('routeChangeComplete', listener);

        return () => {
          Router.events.off('routeChangeComplete', listener);
        };
      }, []);

      return children;
    }
  : ({ children }) => children;
