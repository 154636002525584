import { css } from '@emotion/core';

import SymbolsTtf from '@bitsoflove/pattyn-360/lib/assets/fonts/symbols_pattyn.ttf';
import SymbolsWoff from '@bitsoflove/pattyn-360/lib/assets/fonts/symbols_pattyn.woff';
import SymbolsSvg from '@bitsoflove/pattyn-360/lib/assets/fonts/symbols_pattyn.svg';

export default css`
  @font-face {
    font-family: 'symbols_pattyn';
    src: url(${SymbolsTtf}) format('truetype'),
      url(${SymbolsWoff}) format('woff'), url(${SymbolsSvg}) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'symbols_pattyn' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /*
Friendly update reminder:
If you want code completion documentation, then please update project/src/components/Icon/icons.js when icons are added or removed.
*/

  .icon-user:before {
    content: '\\e900';
  }
  .icon-arrow-circle-down:before {
    content: '\\e901';
  }
  .icon-arrow-circle-left:before {
    content: '\\e902';
  }
  .icon-arrow-circle-right:before {
    content: '\\e903';
  }
  .icon-arrow-circle-up:before {
    content: '\\e904';
  }
  .icon-arrow-down:before {
    content: '\\e905';
  }
  .icon-arrow-left:before {
    content: '\\e906';
  }
  .icon-arrow-right:before {
    content: '\\e907';
  }
  .icon-arrow-thin-right:before {
    content: '\\e908';
  }
  .icon-arrow-up:before {
    content: '\\e909';
  }
  .icon-at:before {
    content: '\\e90a';
  }
  .icon-cart:before {
    content: '\\e90b';
  }
  .icon-close:before {
    content: '\\e90c';
  }
  .icon-download:before {
    content: '\\e90d';
  }
  .icon-dropdown:before {
    content: '\\e90e';
  }
  .icon-electrical-plan:before {
    content: '\\e90f';
  }
  .icon-external-link:before {
    content: '\\e910';
  }
  .icon-faq:before {
    content: '\\e911';
  }
  .icon-home:before {
    content: '\\e912';
  }
  .icon-line:before {
    content: '\\e913';
  }
  .icon-machine:before {
    content: '\\e914';
  }
  .icon-minus:before {
    content: '\\e915';
  }
  .icon-modules:before {
    content: '\\e916';
  }
  .icon-next:before {
    content: '\\e917';
  }
  .icon-notifications:before {
    content: '\\e918';
  }
  .icon-overview-alt:before {
    content: '\\e919';
  }
  .icon-overview:before {
    content: '\\e91a';
  }
  .icon-parameters:before {
    content: '\\e91b';
  }
  .icon-phone:before {
    content: '\\e91c';
  }
  .icon-plus:before {
    content: '\\e91d';
  }
  .icon-positive:before {
    content: '\\e91e';
  }
  .icon-previous:before {
    content: '\\e91f';
  }
  .icon-progress:before {
    content: '\\e920';
  }
  .icon-search:before {
    content: '\\e921';
  }
  .icon-site:before {
    content: '\\e922';
  }
  .icon-spare-part-book:before {
    content: '\\e923';
  }
  .icon-spare-parts-plans:before {
    content: '\\e924';
  }
  .icon-support:before {
    content: '\\e925';
  }
  .icon-tc-airpressure:before {
    content: '\\e926';
  }
  .icon-tc-alert-alarm:before {
    content: '\\e927';
  }
  .icon-tc-alert-message:before {
    content: '\\e928';
  }
  .icon-tc-communication:before {
    content: '\\e929';
  }
  .icon-tc-consumables:before {
    content: '\\e92a';
  }
  .icon-tc-controller-hardware:before {
    content: '\\e92b';
  }
  .icon-tc-dashboard:before {
    content: '\\e92c';
  }
  .icon-tc-delete:before {
    content: '\\e92d';
  }
  .icon-tc-doors:before {
    content: '\\e92e';
  }
  .icon-tc-downstream-blocked:before {
    content: '\\e92f';
  }
  .icon-tc-drive:before {
    content: '\\e930';
  }
  .icon-tc-edit:before {
    content: '\\e931';
  }
  .icon-tc-emergency-stop:before {
    content: '\\e932';
  }
  .icon-tc-export-file:before {
    content: '\\e933';
  }
  .icon-tc-film-cut:before {
    content: '\\e934';
  }
  .icon-tc-film-feed:before {
    content: '\\e935';
  }
  .icon-tc-fuses:before {
    content: '\\e936';
  }
  .icon-tc-info-1:before {
    content: '\\e937';
  }
  .icon-tc-info:before {
    content: '\\e938';
  }
  .icon-tc-initialise:before {
    content: '\\e939';
  }
  .icon-tc-jog-left:before {
    content: '\\e93a';
  }
  .icon-tc-jog-right:before {
    content: '\\e93b';
  }
  .icon-tc-maintenance:before {
    content: '\\e93c';
  }
  .icon-tc-mode-automatic:before {
    content: '\\e93d';
  }
  .icon-tc-mode-conveyor:before {
    content: '\\e93e';
  }
  .icon-tc-mode-manual:before {
    content: '\\e93f';
  }
  .icon-tc-mode-simulate:before {
    content: '\\e940';
  }
  .icon-tc-mode:before {
    content: '\\e941';
  }
  .icon-tc-motor:before {
    content: '\\e942';
  }
  .icon-tc-numbers:before {
    content: '\\e943';
  }
  .icon-tc-pause:before {
    content: '\\e944';
  }
  .icon-tc-product-supply:before {
    content: '\\e945';
  }
  .icon-tc-recipe:before {
    content: '\\e946';
  }
  .icon-tc-sensor:before {
    content: '\\e947';
  }
  .icon-tc-service:before {
    content: '\\e948';
  }
  .icon-tc-settings:before {
    content: '\\e949';
  }
  .icon-tc-software:before {
    content: '\\e94a';
  }
  .icon-tc-start-left:before {
    content: '\\e94b';
  }
  .icon-tc-start-right:before {
    content: '\\e94c';
  }
  .icon-tc-start:before {
    content: '\\e94d';
  }
  .icon-tc-stop:before {
    content: '\\e94e';
  }
  .icon-tc-toggle:before {
    content: '\\e94f';
  }
  .icon-tc-usb:before {
    content: '\\e950';
  }
  .icon-tc-user:before {
    content: '\\e951';
  }
`;
