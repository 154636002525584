/**
 * Replace route parameters (/route/[varable-name]) with provided values.
 * @param {string} route
 * @param {object} query
 * @param {object} config
 * @return string
 */
const composeUrl = (route, query = {}) => {
  if (typeof route !== 'string') {
    return null;
  }

  const pathname = Object.keys(query).reduce((composed, parameterKey) => {
    const parameterSignature = `[${parameterKey}]`;

    if (composed.includes(parameterSignature)) {
      return composed.replace(parameterSignature, query[parameterKey]);
    }

    return composed;
  }, route);

  return pathname;
};

export default composeUrl;
