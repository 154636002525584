/**
 * Replace route parameters (/route/:varable-name) with provided values.
 * @param {object} route
 * @param {object} query
 */
const composeRoute = (route, query, options) => {
  const { pathname } = route || {};
  return {
    pathname,
    query,
    ...options,
  };
};

export default composeRoute;
