import { Observable } from '@apollo/client';

export default function promiseToObservable(promise) {
  return new Observable(subscriber => {
    promise.then(
      token => {
        if (subscriber.closed) {
          return;
        }
        subscriber.next(token);
        subscriber.complete();
      },
      err => {
        // console.log("Subscriber error");
        // throw Error("User is not OAuth type");
        subscriber.error(err);
      },
    );
  });
}
