import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { elementType, func } from 'prop-types';

export { default as QuickAccessModal } from './QuickAccessModal';
export { default as ContactSupportModal } from './ContactSupportModal';

function ModalStack({ ModalComponent, onClose }) {
  const onCloseRef = useRef(onClose);
  const [innerHide, setInnerHide] = useState(false);
  const router = useRouter();

  const innerClose = () => {
    setInnerHide(true);
  };

  useEffect(() => {
    const onRouteChange = () => setInnerHide(true);
    router.events.on('routeChangeStart', onRouteChange);
    return () => router.events.off('routeChangeStart', onRouteChange);
  }, []);

  useEffect(() => {
    onCloseRef.current = onClose;
  }, [onClose]);

  useEffect(() => {
    setInnerHide(false);
  }, [ModalComponent]);

  useEffect(() => {
    if (innerHide) {
      const timeout = setTimeout(() => {
        onCloseRef.current();
      }, 300);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [innerHide]);

  if (ModalComponent) {
    return <ModalComponent hide={innerHide} onClose={innerClose} />;
  }
  return null;
}
ModalStack.propTypes = {
  ModalComponent: elementType,
  onClose: func.isRequired,
};
ModalStack.defaultProps = {
  ModalComponent: null,
};

export default ModalStack;
