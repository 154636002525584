import React from 'react';
import { bool, shape, string } from 'prop-types';
import { Button, Panel } from '@bitsoflove/pattyn-360/lib/components';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import Loader from '~/components/Loader';
import composeRoute from '~/utils/composeRoute';
import routes from '~/routes';

function MachineShortInfoPanel({ machineInstance, loading }) {
  const { t } = useTranslation('pages');
  if (loading) {
    return <Loader />;
  }
  if (!machineInstance) {
    return null;
  }
  const { id: machineInstanceId, machine } = machineInstance;
  const { thumbnail } = machine || {};

  return (
    <Panel>
      <Panel.Header>{machine.name}</Panel.Header>
      <Panel.Body>
        <p>{machine.shortDescription}</p>
        {thumbnail && (
          <p>
            <img
              style={{ width: '100%' }}
              src={thumbnail.src}
              alt={machine.name}
            />
          </p>
        )}
        <p>
          <Link
            href={composeRoute(routes.sparePartBook, { machineInstanceId })}
          >
            <Button variant="secondary" outline icon="spare-part-book" block>
              {t('spare_part_book.title')}
            </Button>
          </Link>
        </p>
        <p>
          <Link
            href={composeRoute(routes.electricalPlan, { machineInstanceId })}
          >
            <Button variant="secondary" outline icon="electrical-plan" block>
              {t('electrical_plan.title')}
            </Button>
          </Link>
        </p>
        <p>
          <Link
            href={composeRoute(routes.manualsAndDocuments, {
              machineInstanceId,
            })}
            passHref
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a style={{ textDecoration: 'none' }}>
              <Button variant="secondary" outline icon="tc-export-file" block>
                {t('manuals_and_documents.title')}
              </Button>
            </a>
          </Link>
        </p>
      </Panel.Body>
    </Panel>
  );
}
MachineShortInfoPanel.propTypes = {
  loading: bool,
  machineInstance: shape({
    name: string,
    shortDescription: string,
  }),
};
MachineShortInfoPanel.defaultProps = {
  loading: false,
  machineInstance: null,
};

export default MachineShortInfoPanel;
