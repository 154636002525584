import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/en.json';
import es from './translations/es.json';
import fr from './translations/fr.json';

import de from './translations/de.json';
import pl from './translations/pl.json';
import it from './translations/it.json';
import beNl from './translations/be-nl.json';

export const ENGLISH = 'en';
export const SPANISH = 'es';
export const FRENCH = 'fr';

export const GERMAN = 'de';
export const POLISH = 'pl';
export const ITALIAN = 'it';
export const BELGIAN_DUTCH = 'nl-BE';

export const DEFAULT_LANGUAGE = ENGLISH;
export const SUPPORTED_LANGUAGES = [
  ENGLISH,
  SPANISH,
  FRENCH,
  GERMAN,
  POLISH,
  ITALIAN,
  BELGIAN_DUTCH,
];
export const LANGUAGE_NAMES = {
  [ENGLISH]: 'English',
  [SPANISH]: 'Español',
  [FRENCH]: 'Français',
  [GERMAN]: 'Deutsch',
  [POLISH]: 'Polski',
  [ITALIAN]: 'Italiano',
  [BELGIAN_DUTCH]: 'Nederlands',
};

const resources = {
  en,
  es,
  fr,
  de,
  pl,
  it,
  [BELGIAN_DUTCH]: beNl,
};

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  debug: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
