import React, { useState } from 'react';
import propTypes from 'prop-types';
import {
  Layout,
  NavigationHeader,
  Button,
} from '@bitsoflove/pattyn-360/lib/components';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { StickyContainer } from 'react-sticky';

import DefaultLayoutWrapper from './styles';
import SidebarContainer from '~/containers/SidebarContainer';
import useBackCallback from '~/hooks/useBackCallback';
import { useAuth } from '~/api/AuthProvider';
import { AUTH_ROUTES } from '~/config/auth';
import ModalStack, {
  QuickAccessModal,
  ContactSupportModal,
} from '~/containers/Modals';
import LanguageSelector from '../LanguageSelector';

function DefaultLayout({ children }) {
  const goBack = useBackCallback();
  const { authenticated, logout } = useAuth();
  const [ModalComponent, setModalComponent] = useState(null);
  const openModal = Component => setModalComponent(() => Component);
  const closeModal = () => setModalComponent(null);

  const { t } = useTranslation('containers');

  return (
    <DefaultLayoutWrapper>
      <SidebarContainer />
      <Layout>
        <NavigationHeader
          actions={
            goBack && (
              <Button icon="arrow-left" outline onClick={goBack}>
                Back
              </Button>
            )
          }
          userMenu={
            authenticated ? (
              [
                <Button
                  key="user-logout"
                  variant="negative"
                  icon="tc-doors"
                  onClick={logout}
                >
                  {t('navigation_header.logout')}
                </Button>,
              ]
            ) : (
              <Link href={AUTH_ROUTES.login}>
                <Button icon="support" variant="transparent">
                  {t('navigation_header.login')}
                </Button>
              </Link>
            )
          }
        >
          <>
            <Button
              icon="support"
              variant="transparent"
              onClick={() => openModal(ContactSupportModal)}
            >
              {t('navigation_header.contact_support')}
            </Button>
            <Button
              icon="spare-parts-plans"
              variant="transparent"
              onClick={() => openModal(QuickAccessModal)}
            >
              {t('navigation_header.spare_parts_book_and_plans')}
            </Button>
            <LanguageSelector />
          </>
        </NavigationHeader>
        <StickyContainer className="sticky-container">
          <Layout.Filled style={{ height: '100%' }}>{children}</Layout.Filled>
        </StickyContainer>
      </Layout>
      <ModalStack ModalComponent={ModalComponent} onClose={closeModal} />
    </DefaultLayoutWrapper>
  );
}

DefaultLayout.propTypes = {
  children: propTypes.element.isRequired,
};

export { default as DefaultMachineLayout } from './DefaultMachineLayout';

export default DefaultLayout;
