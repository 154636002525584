import React from 'react';
import { node, shape, string } from 'prop-types';

import Link from 'next/link';

const LinkComponent = ({ pathname, href, query, children, ...props }) => {
  return (
    <Link
      href={
        href || {
          pathname,
          query,
        }
      }
    >
      <a {...props}>{children}</a>
    </Link>
  );
};

LinkComponent.propTypes = {
  /** href always take precedence over pathname/query props */
  href: string,
  pathname: string,
  query: shape({}),
  children: node,
};

LinkComponent.defaultProps = {
  pathname: null,
  href: null,
  query: {},
  children: null,
};

export default LinkComponent;
