import { useRouter } from 'next/router';
import composeUrl from '~/utils/composeUrl';

function trimmedSlash(path) {
  return path.replace(/\/$/, '');
}

function isActive(router, route, query, deep = false) {
  const checkPath = composeUrl(route.pathname, query);
  if (deep) {
    return router.asPath.indexOf(checkPath) === 0;
  }
  return trimmedSlash(router.asPath) === trimmedSlash(checkPath);
}

/**
 * Checks the given route against the current route to determine link active states.
 * @param {object} route
 * @param {object} query
 * @param {boolean} deep returns true if the currently active route is a sub-path of the route to check
 * @return {object}
 */
export default function useActiveRoute(route, query, deep) {
  const router = useRouter();

  return {
    active: isActive(router, route, query, deep),
    /**
     * In the case you would like to check some other paths against the current location.
     * @param {object} route
     * @param {object} query
     * @param {boolean} deep
     * @return {boolean}
     */
    check: (...newCheck) => isActive(router, ...newCheck),
  };
}
