import gql from 'graphql-tag';

export const AUTHENTICATION_RESULT_FRAGMENT = `
  accessToken
  refreshToken
  expiresIn
  tokenType
  user {
    id
    firstName
    lastName
    email
}
`;

export const LOGIN_MUTATION = gql`
mutation LOGIN($input: LoginInput!) {
  login(input: $input) {
    ${AUTHENTICATION_RESULT_FRAGMENT}
  }
}
`;

export const REFRESH_TOKEN_MUTATION = gql`
mutation REFRESH_TOKEN($input: RefreshTokenInput!) {
  refreshToken(input: $input) {
    ${AUTHENTICATION_RESULT_FRAGMENT}
  }
}
`;

export const REQUEST_PASSWORD_MUTATION = gql`
  mutation REQUEST_PASSWORD($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input)
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
mutation RESET_PASSWORD($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    ${AUTHENTICATION_RESULT_FRAGMENT}
  }
}
`;

export const ACTIVATE_ACCOUNT_MUTATION = gql`
mutation ACTIVATE_ACCOUNT($input: ActivateAccountInput!) {
  activateAccount(input: $input) {
    ${AUTHENTICATION_RESULT_FRAGMENT}
  }
}
`;
