import React from 'react';
import { Icon } from '@bitsoflove/pattyn-360/lib/components';
import { string } from 'prop-types';

const Loader = ({ className }) => (
  <Icon
    style={{ width: 'fit-content' }}
    icon="progress"
    spin
    className={className}
  />
);

Loader.propTypes = {
  className: string,
};
Loader.defaultProps = {
  className: undefined,
};

export default Loader;
