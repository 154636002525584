import React from 'react';
import { Layout, Icon } from '@bitsoflove/pattyn-360/lib/components';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { FaWhatsapp } from 'react-icons/fa/index';

const StyledCountryCode = styled.span`
  display: inline-block;
  min-width: 35px;
`;

const StyledPhoneNumber = styled.span`
  display: inline-block;
  min-width: 130px;
`;

// @todo real contact info
function ContactInfoContainer(props) {
  const { t } = useTranslation('pages');

  return (
    <Layout.Grid addNegativeSpace {...props}>
      <Layout.Column>
        <h3>{t('client_home.contact.support')}</h3>
        <p>
          <a href="mailto:support@pattyn.com">
            <Icon icon="at" /> support@pattyn.com
          </a>
        </p>
        <Layout.Grid addNegativeSpace>
          <Layout.Column>
            <a href="tel:+32483235511">
              <Icon icon="phone" />
              &nbsp;
              <StyledCountryCode>BE</StyledCountryCode>{' '}
              <StyledPhoneNumber>+32 483 23 55 11</StyledPhoneNumber>
            </a>
            <a href="https://wa.me/32483235511">
              <FaWhatsapp />
            </a>
            <br />
            <a href="tel:+33669299263">
              <Icon icon="phone" />
              &nbsp;
              <StyledCountryCode>FR</StyledCountryCode>{' '}
              <StyledPhoneNumber>+33 669 29 92 63</StyledPhoneNumber>
            </a>
            <a href="https://wa.me/33669299263">
              <FaWhatsapp />
            </a>
            <br />
            <a href="tel:+12626490139">
              <Icon icon="phone" />
              &nbsp;
              <StyledCountryCode>USA</StyledCountryCode>{' '}
              <StyledPhoneNumber>+1 262 649 0139</StyledPhoneNumber>
            </a>
            <a href="https://wa.me/12626490139">
              <FaWhatsapp />
            </a>
          </Layout.Column>
          <Layout.Column>
            <p>
              hours: 08.00-17.00 (CET*) 7/7
              <br />
              hours: 08.00-17.00 (CET*) 7/7
              <br />
              hours: 09.00-18.00 (EST*) 7/7
            </p>
          </Layout.Column>
        </Layout.Grid>
        <h3>{t('client_home.contact.parts')}</h3>
        <p>
          <a href="mailto:parts@pattyn.com">
            <Icon icon="at" /> parts@pattyn.com
          </a>
        </p>
      </Layout.Column>
    </Layout.Grid>
  );
}
ContactInfoContainer.propTypes = {};
ContactInfoContainer.defaultProps = {};

export default ContactInfoContainer;
