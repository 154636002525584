import gql from 'graphql-tag';

export const GET_MACHINE_INSTANCE = gql`
  query GET_MACHINE_INSTANCE($id: ID!) {
    app {
      id
      machineInstance(id: $id) {
        id
        machine {
          id
          name
          shortDescription
          description
          thumbnail {
            id
            src
            title
          }
          type {
            slug
          }
          manuals {
            id
            src
            title
          }
          documentations {
            id
            src
            title
          }
        }
        ePlan {
          src
        }
        spareParts {
          id
          src
          originalSrc
        }
        manuals {
          id
          src
          title
        }
        documentations {
          id
          src
          title
        }
      }
    }
  }
`;

export default GET_MACHINE_INSTANCE;
