import React from 'react';

import Sidebar from '~/components/Sidebar';
import { useClientContext } from '~/context/ClientContext';

function SidebarContainer() {
  const {
    loading,
    sites,
    clients,
    setCurrentClientId,
    currentClient,
  } = useClientContext();

  return (
    <Sidebar
      loading={loading}
      sites={sites}
      currentClient={currentClient}
      clients={clients}
      onChangeClient={({ id }) => setCurrentClientId(id)}
    />
  );
}

export default SidebarContainer;
