import React from 'react';
import { Dropdown } from '@bitsoflove/pattyn-360/lib/components';
import { useLanguageContext } from '~/context/LanguageContext';
import { LANGUAGE_NAMES } from '~/i18n';

const LanguageSelector = () => {
  const { language, setLanguage } = useLanguageContext();

  return (
    <Dropdown
      value={{ label: LANGUAGE_NAMES[language], value: language }}
      onChange={({ value }) => setLanguage(value)}
      options={Object.keys(LANGUAGE_NAMES).map(key => ({
        label: LANGUAGE_NAMES[key],
        value: key,
      }))}
    />
  );
};

export default LanguageSelector;
