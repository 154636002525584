import { createStoreon } from 'storeon';
import sitesStore from './sites';

const StoreFactory = () =>
  createStoreon([
    sitesStore,
    process.env.NODE_ENV !== 'production' &&
      // eslint-disable-next-line global-require
      require('storeon/devtools').storeonLogger,
  ]);

export default StoreFactory;
