import React from 'react';
import propTypes from 'prop-types';
import { Overlay, Panel } from '@bitsoflove/pattyn-360/lib/components';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import ContactInfoContainer from '~/containers/ContactInfoContainer';

const StyledPanel = styled(Panel)`
  width: 50vw;
  max-width: 600px;
`;

function ContactSupportModal({ hide, onClose }) {
  const { t } = useTranslation('containers');

  return (
    <Overlay hide={hide}>
      <StyledPanel>
        <Panel.Body spacious>
          <h1>{t('contact_modal.title')}</h1>
          <Panel.Close absolute onClick={onClose} />
          <ContactInfoContainer />
        </Panel.Body>
      </StyledPanel>
    </Overlay>
  );
}
ContactSupportModal.propTypes = {
  hide: propTypes.bool,
  onClose: propTypes.func.isRequired,
};
ContactSupportModal.defaultProps = {
  hide: false,
};

export default ContactSupportModal;
