import React from 'react';
import styled from '@emotion/styled';
import { Global } from '@emotion/core';
import { ENVIRONMENT, PRODUCTION_URL } from '~/config';

const Warning = styled.span`
  font-family: $headings-font-family;
  line-height: 36px;

  top: 0;
  left: 0;

  width: 100%;
  height: 36px;

  text-align: center;

  color: #fff;
  background-color: #dc3545;

  a {
    text-decoration: underline;

    color: #fff;

    &:hover {
      text-decoration: none;
    }
  }
`;

const EnvironmentWarning = () => {
  if (ENVIRONMENT !== 'acceptance') {
    return null;
  }

  return (
    <>
      <Global
        styles={{
          '#__next': {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          },
          '#__next > div': {
            flex: '100% 1',
            height: 'auto',
          },
        }}
      />
      <Warning>
        Warning, this is not the production public environment.{' '}
        {PRODUCTION_URL && (
          <>
            Lost? Go to <a href={PRODUCTION_URL}>{PRODUCTION_URL}</a>
          </>
        )}
      </Warning>
    </>
  );
};

export default EnvironmentWarning;
