import api from '~/api';
import GET_SITES from '~/queries/sites';

export default function sitesStore(store) {
  store.on('@init', () => ({
    sites: {
      loading: false,
      clients: null,
      error: null,
    },
  }));

  store.on('sites/fetch', ({ sites }, force = false) => {
    const { loading, data } = sites;
    if (loading || (data && !force)) {
      return {};
    }

    api
      .query({ query: GET_SITES })
      .then(result => {
        store.dispatch('sites/store', {
          clients: result?.data?.app?.clients,
        });
      })
      .catch(error => {
        store.dispatch('sites/error', error);
      });

    return {
      sites: {
        ...sites,
        loading: true,
      },
    };
  });

  store.on('sites/store', ({ sites }, data) => {
    return {
      sites: {
        ...sites,
        clients: data?.clients,
        error: null,
        loading: false,
      },
    };
  });

  store.on('sites/error', ({ sites }, error) => {
    return {
      sites: {
        ...sites,
        error,
        loading: false,
      },
    };
  });
}
