import React from 'react';
import propTypes from 'prop-types';
import { NavigationSidebar } from '@bitsoflove/pattyn-360/lib/components';

import LinkComponent from '~/components/Link';
import routes from '~/routes';
import useActiveRoute from '~/hooks/useActiveRoute';
import MachineMenuItem from './MachineMenuItem';

const LineMenuItem = ({ line }) => {
  const { name, id: lineId, machineInstances } = line;
  const { active } = useActiveRoute(routes.line, { lineId });

  return (
    <NavigationSidebar.Item
      icon="line"
      label={`    ${name}`}
      LinkComponent={LinkComponent}
      linkProps={{
        ...routes.line,
        query: {
          lineId,
        },
      }}
      active={active}
    >
      {machineInstances &&
        machineInstances.map(machineInstance => (
          <MachineMenuItem
            key={machineInstance.id}
            machine={machineInstance?.machine}
            serialNumber={machineInstance?.serialNumber}
            machineInstanceId={machineInstance?.id}
            isCPRM={machineInstance?.softwareComponentsSource === 'PATTYN_CPRM'}
          />
        ))}
    </NavigationSidebar.Item>
  );
};

LineMenuItem.propTypes = {
  line: propTypes.shape({
    name: propTypes.string.isRequired,
    id: propTypes.string.isRequired,
    machineInstances: propTypes.arrayOf(propTypes.object),
  }).isRequired,
};

export default LineMenuItem;
