/* eslint-disable no-underscore-dangle, react/prop-types */
import React from 'react';
import {
  QuickAccessMachines,
  Panel,
  Button,
} from '@bitsoflove/pattyn-360/lib/components';

import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { bool } from 'prop-types';
import Loader from '~/components/Loader';
import routes from '~/routes';
import LinkComponent from '~/components/Link';
import composeRoute from '~/utils/composeRoute';
import { useClientContext } from '~/context/ClientContext';

const TYPE_SITE = 'Site';
const TYPE_LINE = 'Line';
const TYPE_MACHINE = 'Machine';
const TYPE_MACHINE_INSTANCE = 'MachineInstance';

const TYPE_ICON = {
  [TYPE_SITE]: 'site',
  [TYPE_LINE]: 'line',
  [TYPE_MACHINE_INSTANCE]: 'machine',
  [TYPE_MACHINE]: null,
};

function formatRoute({ __typename: type, id }) {
  switch (type) {
    case TYPE_MACHINE:
    case TYPE_MACHINE_INSTANCE:
      return composeRoute(routes.machine, { machineInstanceId: id });
    case TYPE_LINE:
      return composeRoute(routes.line, { lineId: id });
    default:
      return null;
  }
}

function formatName({ __typename: type, name, machine }) {
  if (type === TYPE_MACHINE_INSTANCE) {
    return machine.name;
  }
  return name;
}

function formatData(data, t, inModal) {
  return data?.map(item => {
    const children = item.lines || item.machineInstances;
    const type = item.__typename;
    return {
      ...item,
      name: formatName(item),
      icon: TYPE_ICON[type],
      LinkComponent,
      linkProps: formatRoute(item),
      children: formatData(children, t, inModal),
      machineActions: inModal && type === TYPE_MACHINE_INSTANCE && (
        <>
          <LinkComponent
            href={composeRoute(routes.sparePartBook, {
              machineInstanceId: item.id,
            })}
          >
            <Button outline block icon="spare-part-book">
              {t('quick_access_machines.spare_parts')}
            </Button>
          </LinkComponent>
          <LinkComponent
            href={composeRoute(routes.electricalPlan, {
              machineInstanceId: item.id,
            })}
          >
            <Button outline block icon="electrical-plan">
              {t('quick_access_machines.electrical_plan')}
            </Button>
          </LinkComponent>
        </>
      ),
    };
  });
}

function PanelWrapper({ t, site, children }) {
  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>
          {t('quick_access_machines.title', { site: site?.name || '' })}
        </Panel.Title>
      </Panel.Header>
      {children}
    </Panel>
  );
}

function QuickAccessMachinesContainer({ inModal, ...props }) {
  const Wrapper = inModal ? React.Fragment : PanelWrapper;
  const { t } = useTranslation('containers');
  const { sites, loading } = useClientContext();

  const { query } = useRouter();
  const { siteId } = query;
  // only render machines belonging to a specific site according to page context
  const site = !inModal && sites?.find(s => siteId && s.id === siteId);
  const data = site ? site.lines : sites;

  return (
    <Wrapper site={site} t={t}>
      {loading ? (
        <Panel.Body>
          <Loader />
        </Panel.Body>
      ) : (
        <QuickAccessMachines
          asPanelBody={!inModal}
          searchPlaceholder={t('quick_access_machines.search.placeholder')}
          items={formatData(data, t, inModal) || []}
          {...props}
        />
      )}
    </Wrapper>
  );
}
QuickAccessMachinesContainer.propTypes = {
  inModal: bool,
};
QuickAccessMachinesContainer.defaultProps = {
  inModal: false,
};

export default QuickAccessMachinesContainer;
