/* eslint-disable */
// @todo re-enable eslint when fixing this
import React, { createContext, useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useRouter } from 'next/router';

const HistoryContext = createContext([]);

HistoryContext.displayName = 'SwitchableThemeContext';

const traceEvent = (router, eventName) =>
  router.events.on(eventName, url =>
    console.log('router event', eventName, url),
  );

export const HistoryContextProvider = ({ children }) => {
  const router = useRouter();
  // const history = { listen: () => {} };
  const [historyStack, setHistoryStack] = useState([]);

  useEffect(() => {
    // @todo fix this, browser forward and backward trigger the exact same chain of events 🤔
    // const handleRouteChange = url => {
    //   console.log('route change', url);
    // };
    traceEvent(router, 'routeChangeStart');
    traceEvent(router, 'routeChangeComplete');
    traceEvent(router, 'beforeHistoryChange');
    traceEvent(router, 'hashChangeStart');
    traceEvent(router, 'hashChangeComplete');
    router.beforePopState((...params) => {
      console.log('router event', 'routeBeforePopState', params);
      return true;
    });
    if (window) {
      window.addEventListener('popstate', () =>
        console.log('router event', 'windowPopstate'),
      );
    }

    // return () => router.events.off('routeChangeComplete', handleRouteChange);
    // history.listen((location, action) => {
    //   if (action === 'PUSH') {
    //     setHistoryStack(current => [...current, location]);
    //   } else if (action === 'POP') {
    //     setHistoryStack(current => current.slice(0, -1));
    //   }
    // });
  }, []);

  return (
    <HistoryContext.Provider value={historyStack}>
      {children}
    </HistoryContext.Provider>
  );
};

HistoryContextProvider.propTypes = {
  children: propTypes.element.isRequired,
};

export const HistoryContextConsumer = HistoryContextProvider.Consumer;

export default HistoryContext;
