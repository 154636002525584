const route = pathname => ({ pathname });

const routes = {
  home: route('/dashboard'),
  login: route('/'),
  requestPassword: route('/account/request-password'),
  site: route('/site/[siteId]'),
  line: route('/line/[lineId]'),
  machine: route('/machine/[machineInstanceId]'),
  frequentlyAskedQuestions: route('/machine/[machineInstanceId]/faqs'),
  frequentlyAskedQuestionDetail: route(
    '/machine/[machineInstanceId]/faqs/[id]',
  ),
  notifications: route('/machine/[machineInstanceId]/notifications'),
  notificationDetail: route('/machine/[machineInstanceId]/notifications/[id]'),
  cprmMessages: route('/machine/[machineInstanceId]/messages'),
  cprmMessageDetail: route('/machine/[machineInstanceId]/messages/[skey]'),
  cprmSettings: route('/machine/[machineInstanceId]/settings'),
  cprmSettingDetail: route('/machine/[machineInstanceId]/settings/[skey]'),
  parameters: route('/machine/[machineInstanceId]/parameters'),
  parameterDetail: route('/machine/[machineInstanceId]/parameters/[id]'),
  modules: route('/machine/[machineInstanceId]/modules'),
  moduleDetail: route('/machine/[machineInstanceId]/modules/[id]'),
  tutorials: route('/machine/[machineInstanceId]/how-to'),
  tutorialDetail: route('/machine/[machineInstanceId]/how-to/[id]'),
  sparePartBook: route('/machine/[machineInstanceId]/spare-part-book'),
  electricalPlan: route('/machine/[machineInstanceId]/electrical-plan'),
  manualsAndDocuments: route(
    '/machine/[machineInstanceId]/manuals-and-documents',
  ),
  manualsAndDocumentsManual: route(
    '/machine/[machineInstanceId]/manuals-and-documents/manual',
  ),
  news: route('/news'),
  newsItem: route('/news/[id]'),
};

const NO_CLIENT_ROUTES = [
  routes.home.pathname,
  // No need to add auth routes since you can't switch client there.
];

export function isClientSpecificRoute(pathname) {
  return !NO_CLIENT_ROUTES.includes(pathname.replace(/\/$/, ''));
}

export default routes;
