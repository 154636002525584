import React from 'react';
import propTypes from 'prop-types';
import { Overlay, Panel } from '@bitsoflove/pattyn-360/lib/components';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import QuickAccessMachinesContainer from '../QuickAccessMachinesContainer';

const StyledPanel = styled(Panel)`
  width: 80vw;
  max-width: 1000px;
`;

function QuickAccessModal({ hide, onClose }) {
  const { t } = useTranslation('containers');

  return (
    <Overlay hide={hide}>
      <StyledPanel>
        <Panel.Body spacious>
          <h1>{t('quick_access_modal.title')}</h1>
          <p>{t('quick_access_modal.intro')}</p>
          <Panel.Close absolute onClick={onClose} />
          <QuickAccessMachinesContainer inModal />
        </Panel.Body>
      </StyledPanel>
    </Overlay>
  );
}
QuickAccessModal.propTypes = {
  hide: propTypes.bool,
  onClose: propTypes.func.isRequired,
};
QuickAccessModal.defaultProps = {
  hide: false,
};

export default QuickAccessModal;
