import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { REFRESH_TOKEN_MUTATION } from '~/queries/authentication';
import httpLink from './httpLink';
import promiseToObservable from './promiseToObservable';

// a separate client to handle login and refresh tokens
export const authClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

// make it compatible with the AuthProvider
authClient.request = async (mutation, { input }) => {
  const result = await authClient.mutate({
    mutation,
    variables: { input },
  });
  return result.data;
};

export const LOCALSTORAGE_KEY = 'pattyn-auth';
let authInfo = null;

export function setToken({ accessToken, refreshToken, expiresIn, user }) {
  const existingData = localStorage.getItem(LOCALSTORAGE_KEY) || {};
  authInfo = {
    ...existingData,

    user,
    accessToken,
    refreshToken,
    expiration: new Date().getTime() + expiresIn,
  };

  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(authInfo));
}

export function clearToken() {
  // @todo should this also invalidate the tokens?
  localStorage.removeItem(LOCALSTORAGE_KEY);
}

function getRefreshToken() {
  authInfo = authInfo || JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));
  return authInfo?.refreshToken || null;
}

/**
 * Try refreshing the access token.
 *
 * - If no refresh token is present will immediately return null (and do nothing else).
 * - If refresh token call errors will clear the login state.
 * - Returns token on success.
 */
async function refreshAccessToken() {
  const refreshToken = getRefreshToken();

  if (refreshToken) {
    try {
      const refreshResult = await authClient.mutate({
        mutation: REFRESH_TOKEN_MUTATION,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            token: refreshToken,
          },
        },
      });

      if (refreshResult?.data?.refreshToken) {
        setToken(refreshResult?.data?.refreshToken);
        return refreshResult.data.refreshToken.accessToken;
      }

      // eslint-disable-next-line no-console
      console.warn('Could not refresh token', refreshResult);
      clearToken();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Refreshing access token failed, clearing login state.', e);
      clearToken();
    }
  }

  // eslint-disable-next-line no-console
  console.warn('Did not refresh token because none was found.');
  return null;
}

/**
 * Get current locally stored access token, check expiration and refresh token if expired.
 */
export async function getAccessToken() {
  // check if token is in local storage
  authInfo = authInfo || JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));

  if (!authInfo) {
    return null;
  }

  const { expiration = 0 } = authInfo;

  if (new Date() > expiration) {
    return refreshAccessToken();
  }

  return authInfo?.accessToken || null;
}

export async function isAuthenticated() {
  return !!(await getAccessToken());
}

/**
 * Intercept errors and try refreshing tokens.
 *
 * WARNING: do not apply to authClient because this can cause feedback loops.
 */
export const authErrorLink = onError(
  ({ graphQLErrors, /* networkError, */ operation, forward }) => {
    if (graphQLErrors) {
      for (let i = 0, l = graphQLErrors.length; i < l; i += 1) {
        // @todo Should we also check error.extensions?
        const error = graphQLErrors[i];
        if (error.debugMessage === 'Unauthorized') {
          const oldHeaders = operation.getContext().headers;

          // the error link expects an observable and can not be async
          // https://github.com/apollographql/apollo-link/issues/646
          return promiseToObservable(refreshAccessToken()).flatMap(
            accessToken => {
              if (accessToken) {
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${accessToken}`,
                  },
                });
                // retry the request, returning the new observable
                return forward(operation);
              }
              return null;
            },
          );
        }
      }
    }
    return null;
  },
);

/**
 * Add access token (if available) to all requests passing through this link.
 */
export const authLink = setContext(async (_, { headers }) => {
  const accessToken = await getAccessToken();

  if (!accessToken) {
    return null;
  }
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
    },
  };
});
