import { useRouter } from 'next/router';
import { useContext } from 'react';
import HistoryContext from '~/context/HistoryContext';

/**
 * Returns a function that triggers the browser history to go to the previous page of this website
 * This hook might return null if you directly routed to the current page
 *
 * @return function|null
 */
export default function useBackCallback() {
  const router = useRouter();
  const historyStack = useContext(HistoryContext);

  return historyStack.length === 0 ? null : router.back;
}
