import styled from '@emotion/styled';
import Sidebar from '~/components/Sidebar';

export default styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  overflow-x: hidden;

  ${Sidebar} {
    display: fixed;
  }
`;
