import React from 'react';
import { useQuery } from '@apollo/client';
import { string } from 'prop-types';
import MachineShortInfoPanel from '~/components/MachineShortInfoPanel';
import { GET_MACHINE_INSTANCE } from '~/queries/machines';

function MachineShortInfoContainer({ machineInstanceId }) {
  const { data, loading: machineLoading } = useQuery(GET_MACHINE_INSTANCE, {
    variables: {
      id: machineInstanceId,
    },
  });
  const { machineInstance } = data?.app || {};

  return (
    <MachineShortInfoPanel
      machineInstance={machineInstance}
      loading={machineLoading}
    />
  );
}
MachineShortInfoContainer.propTypes = {
  machineInstanceId: string.isRequired,
};

export default MachineShortInfoContainer;
