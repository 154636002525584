import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import { I18nextProvider } from 'react-i18next';

import { Global } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';

import { StoreContext } from 'storeon/react';
import { ApolloProvider } from '@apollo/client';
import { GTMPageTracker } from '~/utils/gtag';

import globalStyles from '~/styles/global';
import theme from '~/styles/theme';

import env from '~/utils/env';
import AuthProvider from '~/api/AuthProvider';
import AUTH_CONFIG from '~/config/auth';
import DefaultLayout from '~/containers/DefaultLayout';
import i18n from '~/i18n';
import client from '~/api';
import StoreFactory from '~/store';
import { ClientContextProvider } from '~/context/ClientContext';
import { UserContextProvider } from '~/context/UserContext';
import EnvironmentWarning from '~/components/EnvironmentWarning';
import { LanguageContextProvider } from '~/context/LanguageContext';

function getLayout(Component) {
  const { isAuth } = Component?.options || {};
  return Component.Layout || (isAuth ? React.Fragment : DefaultLayout);
}

export default class MyApp extends App {
  state = {
    store: StoreFactory(),
  };

  static async getInitialProps(appContext) {
    const appProps = await App.getInitialProps(appContext);
    return { ...appProps };
  }

  render() {
    const { Component, pageProps } = this.props;
    const { isAuth, isPublic } = Component?.options || {};
    const { store } = this.state;
    const Layout = getLayout(Component);

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </Head>

        <NextSeo
          title="Pattyn - 360"
          noindex={!env('production')}
          nofollow={!env('production')}
        />
        <I18nextProvider i18n={i18n}>
          <LanguageContextProvider>
            <GTMPageTracker>
              <ThemeProvider theme={theme}>
                <>
                  <EnvironmentWarning />
                  <Global styles={globalStyles({ theme })} />
                  <AuthProvider
                    {...AUTH_CONFIG}
                    isAuth={isAuth}
                    isPublic={isPublic}
                  >
                    <ApolloProvider client={client}>
                      <StoreContext.Provider value={store}>
                        <UserContextProvider>
                          <ClientContextProvider>
                            <I18nextProvider i18n={i18n}>
                              <Layout>
                                <Component {...pageProps} />
                              </Layout>
                            </I18nextProvider>
                          </ClientContextProvider>
                        </UserContextProvider>
                      </StoreContext.Provider>
                    </ApolloProvider>
                  </AuthProvider>
                </>
              </ThemeProvider>
            </GTMPageTracker>
          </LanguageContextProvider>
        </I18nextProvider>
      </>
    );
  }
}
