import { node } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { ENVIRONMENT } from '~/config';
import { DEFAULT_LANGUAGE } from '~/i18n';

export const LANGUAGE_LOCAL_STORAGE_KEY = `pattyn-360_language-${ENVIRONMENT}`;

const LanguageContext = React.createContext();

export function LanguageContextProvider({ children }) {
  const [language, setLanguage] = useLocalStorage(
    LANGUAGE_LOCAL_STORAGE_KEY,
    DEFAULT_LANGUAGE,
  );
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage: newLanguage => {
          i18n.changeLanguage(newLanguage);
          setLanguage(newLanguage);
        },
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

LanguageContextProvider.propTypes = {
  children: node.isRequired,
};

export function useLanguageContext() {
  const result = useContext(LanguageContext);
  return result;
}

export default LanguageContext;
