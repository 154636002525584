import React from 'react';
import propTypes from 'prop-types';
import styled from '@emotion/styled';
import { NavigationSidebar } from '@bitsoflove/pattyn-360/lib/components';
import logo from '@bitsoflove/pattyn-360/lib/assets/images/pattyn.svg';

import SiteMenuItem from './SiteMenuItem';
import routes from '~/routes';
import useActiveRoute from '~/hooks/useActiveRoute';
import LinkComponent from '../Link';
import SidebarClientSelect from './ClientSelect';
import Loader from '../Loader';

const Sidebar = ({
  loading,
  sites,
  clients,
  currentClient,
  onChangeClient,
}) => {
  const { active } = useActiveRoute(routes.home, {});

  return (
    <NavigationSidebar
      logo={logo}
      navHeader={
        loading ? (
          <div style={{ padding: '17px 0 17px 30px' }}>
            <Loader />
          </div>
        ) : (
          <SidebarClientSelect
            clients={clients}
            currentClient={currentClient}
            onChangeClient={onChangeClient}
          />
        )
      }
    >
      <NavigationSidebar.Item
        id="dashboard"
        icon="home"
        label="Client Home"
        LinkComponent={LinkComponent}
        linkProps={routes.home}
        active={active}
      />

      {sites && sites.map(site => <SiteMenuItem key={site.id} site={site} />)}
    </NavigationSidebar>
  );
};

Sidebar.propTypes = {
  loading: propTypes.bool,
  sites: propTypes.arrayOf(propTypes.object),
  clients: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      name: propTypes.string,
    }),
  ),
  currentClient: propTypes.shape({
    id: propTypes.string,
    name: propTypes.string,
  }),
  onChangeClient: propTypes.func.isRequired,
};

Sidebar.defaultProps = {
  loading: false,
  sites: [],
  clients: [],
  currentClient: null,
};

export default styled(Sidebar)``;
